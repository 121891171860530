import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import Button from "react-bootstrap/Button";

export const ExpandButton = ({ open, onClick }) => {
  const ToggleIcon = open ? ChevronUpMinor : ChevronDownMinor;
  const buttonStyle = { borderRadius: "50%", width: 40, height: 40, border: "none" };

  return (
    <Button onClick={onClick} variant="outline-light" style={buttonStyle}>
      <ToggleIcon />
    </Button>
  );
};
