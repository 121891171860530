import { OrderEventType, TrackingEventStatus } from "./enums";

export const TrackingEventStatusToOrderEventType: {
  [key in TrackingEventStatus]: OrderEventType;
} = {
  PRE_TRANSIT: OrderEventType.CREATED,
  SORTATION_ARRIVAL: OrderEventType.IN_TRANSIT,
  IN_TRANSIT: OrderEventType.IN_TRANSIT,
  OUT_FOR_DELIVERY: OrderEventType.IN_TRANSIT,
  DELIVERED: OrderEventType.DELIVERED,
  RETURN_TO_SENDER: OrderEventType.CANCELLED,
  FAILURE: OrderEventType.IN_TRANSIT,
  CANCELED: OrderEventType.CANCELLED,
  UNKNOWN: OrderEventType.PROCESSING,
};
