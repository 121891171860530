import { Text, TextProps } from "@shopify/polaris";
import { TrackingDetailResponse } from "common/types";
import { isToday, parseISO } from "date-fns";
import { FormattedMessage } from "react-intl";
import { areAllPkgsDelivered } from "~/common/helpers/OrderEvents/helpers/areAllPkgsDelivered";
import { getLatestPackageTimes } from "~/common/helpers/OrderEvents/helpers/getLatestEstimatedTimes";
import { FulfillmentShipmentStatus, FulfillmentShipmentWithoutAddress } from "~/common/types";
import { DateFormat, isDateToday, parseDate } from "../date";

const format = (date: Date, timeZone: string | undefined) => parseDate(date, DateFormat.WeekMonthDay, timeZone);
const getRelativeDate = (date: Date, timeZone: string | undefined) => {
  return isDateToday(date) ? `today ${parseDate(date, DateFormat.Time)}` : format(date, timeZone);
};
const getRelativeDeliveredDate = (date: Date, timeZone: string | undefined) => {
  return isDateToday(date) ? `today` : format(date, timeZone);
};

export function getShipmentStatus(shipment: FulfillmentShipmentWithoutAddress, destinationTz: string): React.ReactNode {
  const { estimatedDeliveryTime, deliverrCancelTime, status } = shipment;
  const relativeDate = getRelativeDate(estimatedDeliveryTime!, destinationTz);

  if (areAllPkgsDelivered([shipment])) {
    const latestTimes = getLatestPackageTimes([shipment]);
    const relativeDeliveredDate = getRelativeDeliveredDate(latestTimes.packageDeliverTime!, destinationTz);
    const textProps = { as: "h1", variant: "headingLg" } as TextProps;
    const packageDeliverTime = latestTimes.packageDeliverTime;
    if (packageDeliverTime && isToday(parseISO(new Date(packageDeliverTime).toISOString()))) {
      textProps.color = "success";
    }

    return (
      <Text {...textProps} fontWeight="semibold">
        <FormattedMessage
          id={`shipment.status.delivered.${shipment.id}`}
          defaultMessage={`Delivered ${relativeDeliveredDate}`}
        />
      </Text>
    );
  }

  switch (status) {
    case FulfillmentShipmentStatus.CREATED:
    case FulfillmentShipmentStatus.WAREHOUSE:
      return (
        <Text as="h1" variant="headingLg">
          <FormattedMessage
            id={`shipment.status.created.${shipment.id}`}
            defaultMessage={`Estimated by ${relativeDate}`}
          />
        </Text>
      );
    case FulfillmentShipmentStatus.SHIPPED:
    case FulfillmentShipmentStatus.PARTIALLY_SHIPPED:
    case FulfillmentShipmentStatus.OVER_SHIPPED:
      return (
        <Text as="h1" variant="headingLg">
          <FormattedMessage id={`shipment.status.shipped.${shipment.id}`} defaultMessage={`Arriving ${relativeDate}`} />
        </Text>
      );
    case FulfillmentShipmentStatus.CANCELLED:
      return (
        <Text as="h1" variant="headingLg">
          <FormattedMessage
            id={`shipment.status.cancelled.${shipment.id}`}
            defaultMessage={`Cancelled ${format(deliverrCancelTime!, destinationTz)}`}
          />
        </Text>
      );
    case FulfillmentShipmentStatus.ERROR:
      return (
        <Text as="h1" variant="headingLg">
          <FormattedMessage
            id={`shipment.status.error.${shipment.id}`}
            defaultMessage="There was an error with this shipment."
          />
        </Text>
      );
  }
}

export const getLatestTrackingEvent = (
  trackingHistory: TrackingDetailResponse["trackingHistory"]
): TrackingDetailResponse["trackingHistory"][0] =>
  trackingHistory.reduce((first, second) => (first.eventTime > second.eventTime ? first : second));
