import { FulfillmentPackageWithProductData, PublicOrderSummary } from "common/types";
import { identity, pick, pickBy, pipe } from "lodash/fp";
import { TrackedShipment } from "../../types/TrackedShipment";

const getPackageData = (pkg: FulfillmentPackageWithProductData) => {
  return pipe(
    pick([
      "attemptedDeliveryTime",
      "createdAt",
      "deliveryTime",
      "estimatedDeliveryTime",
      "facilityTime",
      "items",
      "pickUpTime",
      "shipTime",
      "shippingMethod",
      "trackingCode",
      "lastTrackingEventTime",
      "warehousePickupTime",
    ]),
    pickBy(identity)
  )(pkg);
};

const getPackages = (shipment: PublicOrderSummary["shipments"][0], shipmentData) => {
  return shipment.packages.map((pkg: FulfillmentPackageWithProductData) => {
    const packageData = getPackageData(pkg);

    return {
      ...shipmentData,
      ...packageData,
    };
  });
};
export const flattenShipments = (shipments: PublicOrderSummary["shipments"]): TrackedShipment[] =>
  shipments.reduce<TrackedShipment[]>((acc, shipment) => {
    const shipmentData = pick(
      ["estimatedDeliveryTime", "estimatedShipTime", "shippingMethod", "status", "items", "id"],
      shipment
    );
    if (!shipment.packages.length) {
      return [...acc, shipmentData];
    }
    const packages = getPackages(shipment, shipmentData);

    return [...acc, ...packages];
  }, [] as TrackedShipment[]);
