import { DeserializedOrderSummary, MappedTrackingDetail, TrackingDetailResponse } from "common/types";
import { getOrderStatus, getStatusFromTrackingDetail } from "common/utils/order/status";
import { StatusCard } from "~/common/components/StatusCard";
import { CarrierOnlyEstimationData } from "~/common/types/CarrierOnlyEstimationData";
import { getTrackingDetailFromMap } from "../common/utils/tracking/getTrackingDetailsFromMap";

interface OrderStatusCardProps {
  deserializedOrder?: DeserializedOrderSummary;
  parcelTrackingDetails?: TrackingDetailResponse;
  numSteps?: number;
  trackingDetailMap?: MappedTrackingDetail;
  carrierOnlyEstimationData?: CarrierOnlyEstimationData;
}

export function OrderStatusCard({
  deserializedOrder,
  parcelTrackingDetails,
  trackingDetailMap,
  numSteps = 4,
  carrierOnlyEstimationData,
}: OrderStatusCardProps) {
  const { heading, background, body, icon, step, isToday } = parcelTrackingDetails
    ? getStatusFromTrackingDetail(parcelTrackingDetails)
    : getOrderStatus(deserializedOrder!, getTrackingDetailFromMap(trackingDetailMap), carrierOnlyEstimationData);

  return (
    <StatusCard
      background={background}
      heading={heading}
      body={body}
      icon={icon}
      step={step}
      isToday={isToday}
      numSteps={numSteps}
    />
  );
}
