import { Box, Frame, Inline, Layout } from "@shopify/polaris";
import { getOrderEventTimelineItems, getTimelineItems, Timeline } from "common/components/Timeline";
import { DeserializedOrderSummary, OrderStatus } from "common/types";
import { MappedTrackingDetail, TrackingDetailResponse } from "common/types/MappedTrackingDetail";

import { datadogRum } from "@datadog/browser-rum";
import { DeliveryMethod } from "common/components/DeliveryMethod";
import { getDeliveryTimeTz } from "common/helpers/OrderEvents/helpers/timezones";
import { Container } from "react-bootstrap";
import { CarrierOnlyEstimationData } from "~/common/types/CarrierOnlyEstimationData";
import { getShippingMethod } from "~/common/utils/carrier";
import { getDeliveryImageUrl } from "~/common/utils/tracking/getDeliveryImageUrl";
import { ShipmentItems } from "../shipment/ShipmentItems";
import { Shipments } from "../shipment/Shipments";
import { OrderStatusCard } from "./OrderStatusCard";
import { TopBar } from "./TopBar";

export interface OrderPageProps {
  deserializedOrder?: DeserializedOrderSummary;
  sellerUrl?: string;
  trackingDetailMap?: MappedTrackingDetail;
  parcelTrackingDetails?: TrackingDetailResponse;
  carrierOnlyEstimationData?: CarrierOnlyEstimationData;
}

export const OrderPage: React.FC<OrderPageProps> = ({
  deserializedOrder,
  sellerUrl,
  trackingDetailMap,
  parcelTrackingDetails,
  carrierOnlyEstimationData,
}) => {
  const order = deserializedOrder?.order;
  const events = deserializedOrder?.events;
  const destinationTz = order ? getDeliveryTimeTz(order.destinationTz) : getDeliveryTimeTz(undefined);
  const timelineItems = deserializedOrder
    ? getOrderEventTimelineItems(deserializedOrder.events)
    : parcelTrackingDetails
    ? getTimelineItems(parcelTrackingDetails, { estimatedDeliveryTime: undefined, estimatedShipTime: undefined }, [])
    : [];

  datadogRum.addTiming("next_order_page");

  const detailMarkup =
    order && trackingDetailMap && events ? (
      order.status === OrderStatus.CANCELLED ? (
        <div className="p-4">
          <Timeline items={timelineItems} destinationTz={destinationTz} />
          <ShipmentItems
            items={order.originalItems}
            originalItems={order.originalItems}
            destinationTz={destinationTz}
            orderStatus={order.status as OrderStatus}
          />
        </div>
      ) : (
        <Shipments
          shipments={order.shipments}
          originalItems={order.originalItems}
          destinationTz={destinationTz}
          trackingDetailMap={trackingDetailMap}
          orderEvents={events}
          carrierOnlyEstimationData={carrierOnlyEstimationData}
          orderStatus={order.status as OrderStatus}
        />
      )
    ) : (
      <div className="p-4">
        <Timeline items={timelineItems} destinationTz={destinationTz} />
      </div>
    );

  return (
    <Frame>
      <Box background="surface" minHeight="100%" paddingBlockEnd="12">
        {deserializedOrder && <TopBar order={deserializedOrder.order} sellerUrl={sellerUrl} />}
        <Inline align="center">
          <div className="m-0 col-12 col-md-8 pt-0 pt-md-4" style={{ maxWidth: "768px" }}>
            <div className="d-flex flex-wrap justify-content-center align-items-start">
              <OrderStatusCard
                deserializedOrder={deserializedOrder}
                parcelTrackingDetails={parcelTrackingDetails}
                trackingDetailMap={trackingDetailMap}
                carrierOnlyEstimationData={carrierOnlyEstimationData}
              />
              <div className="container fluid p-0">{detailMarkup}</div>
              {/* Adds the Delivery Method + Carrier Tracking Link for Unbundled Parcel Tracking */}
              {parcelTrackingDetails && (
                <div className="container fluid p-4">
                  <DeliveryMethod
                    trackingCode={parcelTrackingDetails.trackingCode}
                    shippingMethod={getShippingMethod(parcelTrackingDetails.trackingCode, parcelTrackingDetails)}
                    deliveryImageUrl={getDeliveryImageUrl(parcelTrackingDetails)}
                  />
                </div>
              )}
            </div>
          </div>
        </Inline>
      </Box>
    </Frame>
  );
};
