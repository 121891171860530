import { datadogRum } from "@datadog/browser-rum";
import { AlphaStack, Box, Collapsible, Columns, Inline, Rows, Text } from "@shopify/polaris";
import { ExpandButton } from "common/components/ExpandButton/ExpandButton";
import { FulfillmentShipmentItem, ItemWithProductData, OrderStatus } from "common/types";
import { DateFormat, parseDate } from "common/utils/date";
import { getShippingStatusText } from "common/utils/order/status";
import { useCallback, useState } from "react";
import { Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { Client } from "react-hydration-provider";
import { FormattedMessage } from "react-intl";
import { Item, ItemSize } from "../package/Item";
export interface ShipmentItemsProps {
  items: FulfillmentShipmentItem[] | ItemWithProductData[];
  originalItems: ItemWithProductData[];
  estimatedShipTime?: Date;
  destinationTz: string;
  orderStatus?: OrderStatus;
}

export const ShipmentItems: React.FC<ShipmentItemsProps> = ({
  items,
  originalItems,
  estimatedShipTime,
  destinationTz,
  orderStatus,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const firstItem = items[0];

  const handleToggle = useCallback(() => {
    datadogRum.addAction("user_expand_shipment_items", { opening: !open, component: "ShipmentItems" });
    setOpen(!open);
  }, [open]);

  const itemCountMarkup = (
    <Text as="span" variant="bodyMd" fontWeight="semibold">
      <FormattedMessage id={`shipmentpackage.items.${firstItem.dsku}`} defaultMessage={"Items in this shipment"} />
    </Text>
  );

  const firstItemMarkup =
    items.length === 1 ? (
      <Item item={firstItem} originalItems={originalItems} hasIcon qtyRight={false} size={ItemSize.md} />
    ) : (
      <div className="d-flex row flex-nowrap" style={{ flex: "1" }}>
        <ExpandButton onClick={handleToggle} open={open} />
        {!open ? (
          <Item
            item={firstItem}
            originalItems={originalItems}
            hasIcon={false}
            qtyRight={false}
            moreItemCount={items.length - 1}
            size={ItemSize.md}
          />
        ) : (
          itemCountMarkup
        )}
      </div>
    );
  const packageItem = items[0];
  return (
    <ListGroup className="rounded-4 w-100">
      <ListGroup.Item className="p-3">
        <Inline wrap={false} align="space-between">
          {firstItemMarkup}
          <Inline wrap={false} gap="4">
            <AlphaStack align="center">
              <Client>
                <Text as="span" variant="bodyMd">
                  <FormattedMessage
                    id={`shipment item.shipping.${packageItem.dsku}`}
                    defaultMessage={getShippingStatusText(estimatedShipTime, orderStatus, destinationTz)}
                  />
                </Text>
              </Client>
            </AlphaStack>
          </Inline>
        </Inline>
        <Collapsible
          open={open}
          id="basic-collapsible"
          transition={{ duration: "100ms", timingFunction: "ease-in-out" }}
          expandOnPrint
        >
          <Box paddingBlockStart="4" paddingBlockEnd="3">
            <div className="row">
              {items.map((item: FulfillmentShipmentItem | ItemWithProductData) => (
                <Item key={item.dsku} originalItems={originalItems} item={item} qtyRight />
              ))}
            </div>
          </Box>
        </Collapsible>
      </ListGroup.Item>
    </ListGroup>
  );
};
