import { datadogRum } from "@datadog/browser-rum";
import { json, LoaderFunction } from "@remix-run/node";
import { useLoaderData, useParams, useRouteError } from "@remix-run/react";
import { HydrationProvider } from "react-hydration-provider";
import { getAllData, isValidOrderTrackingId } from "~/common/server/order.server";
import { InvalidOrderCard } from "~/order/InvalidOrderCard";
import { OrderUI } from "~/OrderUI";
import { UnifiedTrackingUI } from "~/UnifiedTrackingUI";

export const loader: LoaderFunction = async ({ params }) => {
  const orderIdOrTrackingCode = params.orderId;
  if (orderIdOrTrackingCode && isValidOrderTrackingId(orderIdOrTrackingCode)) {
    return getAllData(orderIdOrTrackingCode);
  }
  return json({});
};

export default function OrderDetails() {
  const data = useLoaderData<typeof loader>();
  const {
    order,
    sellerUrl,
    deserializedOrder,
    trackingDetailMap,
    parcelTrackingDetails,
    unifiedTrackingDetails,
    carrierOnlyEstimationData,
  } = data;

  const params = useParams();

  if (!parcelTrackingDetails && !unifiedTrackingDetails) {
    datadogRum.addRumGlobalContext("orderId", params.orderId);
  } else {
    datadogRum.addRumGlobalContext("trackingCode", params.orderId);
  }

  if (!parcelTrackingDetails && !order && !unifiedTrackingDetails) {
    return <InvalidOrderCard />;
  }

  datadogRum.addRumGlobalContext("businessName", order?.businessName);

  return (
    <HydrationProvider>
      {unifiedTrackingDetails ? (
        <UnifiedTrackingUI unifiedTrackingDetails={unifiedTrackingDetails} />
      ) : (
        <OrderUI
          deserializedOrder={deserializedOrder}
          sellerUrl={sellerUrl}
          trackingDetailMap={trackingDetailMap}
          parcelTrackingDetails={parcelTrackingDetails}
          carrierOnlyEstimationData={carrierOnlyEstimationData}
        />
      )}
    </HydrationProvider>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  const params = useParams();
  const errorInfo = { params, msg: "Index error boundary" };
  datadogRum.addError(error, errorInfo);
  // eslint-disable-next-line no-console
  console.error({ error, ...errorInfo });

  return <InvalidOrderCard />;
}
