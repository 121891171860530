import { datadogRum } from "@datadog/browser-rum";
import { useParams, useRouteError } from "@remix-run/react";
import { DeserializedOrderSummary } from "common/types";
import { MappedTrackingDetail, TrackingDetailResponse } from "common/types/MappedTrackingDetail";

import { InvalidOrderCard } from "~/order/InvalidOrderCard";
import { OrderPage } from "~/order/OrderPage";
import { CarrierOnlyEstimationData } from "./common/types/CarrierOnlyEstimationData";

interface OrderNextUIProps {
  deserializedOrder?: DeserializedOrderSummary;
  sellerUrl?: string;
  trackingDetailMap?: MappedTrackingDetail;
  parcelTrackingDetails?: TrackingDetailResponse;
  carrierOnlyEstimationData?: CarrierOnlyEstimationData;
}

export function OrderUI({
  deserializedOrder,
  sellerUrl,
  trackingDetailMap,
  parcelTrackingDetails,
  carrierOnlyEstimationData,
}: OrderNextUIProps) {
  return (
    <OrderPage
      deserializedOrder={deserializedOrder}
      sellerUrl={sellerUrl}
      trackingDetailMap={trackingDetailMap}
      parcelTrackingDetails={parcelTrackingDetails}
      carrierOnlyEstimationData={carrierOnlyEstimationData}
    />
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  const params = useParams();
  datadogRum.addError(error, { params, ui: "Next UI" });
  return <InvalidOrderCard />;
}
