import { datadogRum } from "@datadog/browser-rum";
import { useParams, useRouteError } from "@remix-run/react";

import { ParcelTrackingEventsResponse } from "@deliverr/parcel-client";
import { InvalidOrderCard } from "~/order/InvalidOrderCard";
import { UnifiedTrackingPage } from "./unified-tracking/UnifiedTrackingPage";

interface UnifiedTrackingUIProps {
  unifiedTrackingDetails?: ParcelTrackingEventsResponse;
}

export function UnifiedTrackingUI({ unifiedTrackingDetails }: UnifiedTrackingUIProps) {
  return <UnifiedTrackingPage unifiedTrackingDetails={unifiedTrackingDetails} />;
}

export function ErrorBoundary() {
  const error = useRouteError();
  const params = useParams();
  datadogRum.addError(error, { params, ui: "Next UI" });
  return <InvalidOrderCard />;
}
