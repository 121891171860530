import { datadogRum } from "@datadog/browser-rum";
import { Link, useParams } from "@remix-run/react";
import { AlphaStack, Box, Collapsible, Columns, Inline, Text } from "@shopify/polaris";
import { ExpandButton } from "common/components/ExpandButton/ExpandButton";
import {
  DeliverrAddress,
  FulfillmentPackage,
  FulfillmentPackageItem,
  FulfillmentPackageItemProduct,
  ItemWithProductData,
} from "common/types";
import { TrackingDetailResponse } from "common/types/MappedTrackingDetail";
import { formatAddress } from "common/utils/address/formatAddress";
import { carrierIcons, carrierNames, getCarrier } from "common/utils/carrier";
import { DateFormat, parseDate } from "common/utils/date";
import { getLatestTrackingEvent } from "common/utils/shipment/shipmentStatus";
import { useCallback, useState } from "react";
import { Client } from "react-hydration-provider";
import { FormattedMessage } from "react-intl";
import { Item } from "../package/Item";

export interface ShipmentPackageProps {
  shipmentPackage: FulfillmentPackage;
  originalItems: ItemWithProductData[];
  from: DeliverrAddress;
  boxName: string;
  destinationTz: string;
  trackingDetail: TrackingDetailResponse;
}

// If we don't have tracking, this may be an old event and no longer have tracking
// data.  Show the best data we have.
const getLastFallbackEvent = (pkg: FulfillmentPackage) => {
  const { shipTime, deliveryTime } = pkg;

  const baseEvent = { message: "Not tracked", eventTime: null, location: null };
  if (deliveryTime) {
    return { ...baseEvent, message: "Delivered", eventTime: deliveryTime };
  } else if (shipTime) {
    return { ...baseEvent, message: "Shipped", eventTime: shipTime };
  }
  return baseEvent;
};

export const ShipmentPackage: React.FC<ShipmentPackageProps> = ({
  shipmentPackage,
  originalItems,
  trackingDetail,
  destinationTz,
}) => {
  const { id, shippingMethod, items, trackingCode } = shipmentPackage;
  const { orderId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const carrier = getCarrier(shippingMethod);
  const latestEvent = trackingDetail
    ? getLatestTrackingEvent(trackingDetail.trackingHistory)
    : getLastFallbackEvent(shipmentPackage);
  const { message, location, eventTime } = latestEvent;
  const addressLine = location ? formatAddress(location) : null;
  const parsedEventTime = eventTime ? parseDate(eventTime, DateFormat.ShortDateTime, destinationTz) : null;
  const detailLine = [addressLine, parsedEventTime].filter((o) => o).join(" - ");

  const handleToggle = useCallback(() => {
    datadogRum.addAction("expand_shipment_package", { opening: !open, component: "ShipmentPackage" });
    setOpen(!open);
  }, [open]);

  const mediaMarkup = (
    <img
      src={carrierIcons[carrier]}
      alt={carrierNames[carrier]}
      style={{ borderRadius: "50%", height: 42, width: 42 }}
    />
  );

  const handleExpand = () => {
    datadogRum.addAction("user_view_tracking", { url: `/${orderId}/tracking/${trackingCode}` });
  };

  return (
    <div className="hover-outline-heavy" key={shipmentPackage.id}>
      <Inline gap="0" wrap={false}>
        <Box paddingInlineEnd="0">
          <ExpandButton onClick={handleToggle} open={open} />
        </Box>
        <Link
          id={String(id)}
          to={`/${orderId}/tracking/${trackingCode}`}
          prefetch="intent"
          className="w-100 p-3 ps-0 text-decoration-none text-reset"
          onClick={handleExpand}
        >
          <Box width="100%" padding="0">
            <Inline wrap={false} align="space-between">
              <Inline wrap={false} gap="4">
                {mediaMarkup}
                <AlphaStack align="start" gap="0">
                  <Text variant="bodyMd" fontWeight="bold" as="h3" alignment="start">
                    {message}
                  </Text>
                  <Client>
                    <Text variant="bodyMd" as="span" color="subdued">
                      {detailLine}
                    </Text>
                  </Client>
                </AlphaStack>
              </Inline>
              {!open && (
                <Item
                  item={items[0] as FulfillmentPackageItemProduct}
                  originalItems={originalItems}
                  moreItemCount={items.length - 1}
                  hasIcon={false}
                  align="end"
                />
              )}
            </Inline>
          </Box>
        </Link>
      </Inline>
      <Collapsible
        open={open}
        id="basic-collapsible"
        transition={{ duration: "100ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        <Box paddingBlockEnd="4" paddingInlineEnd="4" paddingInlineStart="4">
          <Box paddingBlockEnd="3">
            <Text as="span" variant="bodyMd" fontWeight="semibold">
              <FormattedMessage id={`shipmentpackage.items.${id}`} defaultMessage={"Items in this package"} />
            </Text>
          </Box>
          <div className="row">
            {items.map((item: FulfillmentPackageItem) => (
              <Item
                key={item.dsku}
                originalItems={originalItems}
                item={item as FulfillmentPackageItemProduct}
                qtyRight
              />
            ))}
          </div>
        </Box>
      </Collapsible>
    </div>
  );
};
