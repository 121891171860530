import { OrderEventType } from "common/types";
import BasketIcon from "./icons/Basket.svg";
import PackageCancelledIcon from "./icons/PackageCancelled.svg";
import PackageOpenIcon from "./icons/PackageOpen.svg";
import PackageSealedIcon from "./icons/PackageSealed.svg";
import TruckIcon from "./icons/Truck.svg";

export const OrderEventTypeText: Record<OrderEventType, string> = {
  [OrderEventType.CREATED]: "Order placed",
  [OrderEventType.INGESTION]: "Processing",
  [OrderEventType.PROCESSING]: "Processing",
  [OrderEventType.SHIPPED]: "In Transit",
  [OrderEventType.IN_TRANSIT]: "In Transit",
  [OrderEventType.TRACKING_REPORTED]: "In Transit",
  [OrderEventType.REPLACEMENT_SHIPPED]: "In Transit",
  [OrderEventType.PARTIALLY_FULFILLED]: "Partially Fulfilled",
  [OrderEventType.DELIVERED]: "Delivered",
  [OrderEventType.INVALID_ADDRESS]: "Invalid Address",
  [OrderEventType.CANCELLED]: "Cancelled",
};

export const OrderEventTypeIcon: Record<OrderEventType, string> = {
  [OrderEventType.CREATED]: BasketIcon,
  [OrderEventType.INGESTION]: PackageOpenIcon,
  [OrderEventType.PROCESSING]: PackageOpenIcon,
  [OrderEventType.SHIPPED]: TruckIcon,
  [OrderEventType.IN_TRANSIT]: TruckIcon,
  [OrderEventType.TRACKING_REPORTED]: TruckIcon,
  [OrderEventType.PARTIALLY_FULFILLED]: TruckIcon,
  [OrderEventType.REPLACEMENT_SHIPPED]: TruckIcon,
  [OrderEventType.DELIVERED]: PackageSealedIcon,
  [OrderEventType.INVALID_ADDRESS]: PackageSealedIcon,
  [OrderEventType.CANCELLED]: PackageCancelledIcon,
};
