import { datadogRum } from "@datadog/browser-rum";
import { Box, Frame, Inline, Layout } from "@shopify/polaris";
import { UnifiedTrackingStatusCard } from "./UnifiedTrackingStatusCard";

import { ParcelTrackingEventsResponse } from "@deliverr/parcel-client";
import { DeliveryMethod } from "common/components/DeliveryMethod";
import { getTrackingTimelineItems, Timeline } from "common/components/Timeline";
import { getDeliveryTimeTz } from "common/helpers/OrderEvents/helpers/timezones";

export interface UnifiedTrackingPageProps {
  unifiedTrackingDetails?: ParcelTrackingEventsResponse;
}

export const UnifiedTrackingPage: React.FC<UnifiedTrackingPageProps> = ({ unifiedTrackingDetails }) => {
  const destinationTz = getDeliveryTimeTz(unifiedTrackingDetails?.destinationTimezone);
  const shippingMethod = unifiedTrackingDetails?.trackingHistory?.[0]?.shippingMethod;
  const timelineItems = unifiedTrackingDetails
    ? getTrackingTimelineItems(unifiedTrackingDetails?.trackingHistory, {
        estimatedDeliveryTime: undefined,
        estimatedShipTime: undefined,
      })
    : [];

  datadogRum.addTiming("next_order_page");

  return (
    <Frame>
      <Box background="surface" minHeight="100%" paddingBlockEnd="12">
        <Inline align="center">
          <Box maxWidth="680px" paddingBlockStart="5">
            <Layout>
              <Layout.Section>
                <UnifiedTrackingStatusCard unifiedTrackingDetails={unifiedTrackingDetails} />
              </Layout.Section>
              <Layout.Section>
                <div className="p-4">
                  <Timeline items={timelineItems} destinationTz={destinationTz} />
                </div>
              </Layout.Section>
              {/* Adds the Delivery Method + Carrier Tracking Link for Unbundled Parcel Tracking */}
              {unifiedTrackingDetails?.trackingCode && shippingMethod && (
                <Layout.Section>
                  <div className="p-4">
                    <DeliveryMethod
                      trackingCode={unifiedTrackingDetails?.trackingCode}
                      deliveryImageUrl={unifiedTrackingDetails?.deliveryInformation?.proofOfDelivery}
                      shippingMethod={shippingMethod}
                    />
                  </div>
                </Layout.Section>
              )}
            </Layout>
          </Box>
        </Inline>
      </Box>
    </Frame>
  );
};
