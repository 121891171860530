import { ParcelTrackingEventsResponse } from "@deliverr/parcel-client";
import { StatusCard } from "~/common/components/StatusCard";
import { getStatusFromUnifiedTrackingDetail } from "./utils/getStatusFromUnifiedTrackingDetail";

interface UnifiedTrackingStatusCardProps {
  unifiedTrackingDetails?: ParcelTrackingEventsResponse;
  numSteps?: number;
}

export function UnifiedTrackingStatusCard({ unifiedTrackingDetails, numSteps = 4 }: UnifiedTrackingStatusCardProps) {
  const status = unifiedTrackingDetails ? getStatusFromUnifiedTrackingDetail(unifiedTrackingDetails) : undefined;
  if (!status) {
    return null;
  }
  const { heading, background, body, icon, step, isToday } = status;
  return (
    <StatusCard
      background={background}
      heading={heading}
      body={body}
      icon={icon}
      step={step}
      isToday={isToday}
      numSteps={numSteps}
    />
  );
}
