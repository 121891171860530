import { AlphaStack, AlphaStackProps, Box, Inline, Text } from "@shopify/polaris";
import { FulfillmentPackageItemProduct, FulfillmentShipmentItem, ItemWithProductData } from "common/types";
import { getItem } from "common/utils/item";
import ItemIcon from "common/utils/item/icons/itemIcon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage, FormattedPlural } from "react-intl";

export enum ItemSize {
  sm = "200px",
  md = "300px",
}

interface ItemProps {
  item: FulfillmentPackageItemProduct | FulfillmentShipmentItem;
  originalItems: ItemWithProductData[];
  qtyRight?: boolean;
  moreItemCount?: number;
  hasIcon?: boolean;
  size?: ItemSize;
  align?: AlphaStackProps["align"];
}

const Quantity: React.FC<{ qty: number }> = ({ qty }) => (
  <Inline wrap={false}>
    <Text variant="bodySm" as="span" color="subdued">
      {`Qty ${qty}`}
    </Text>
  </Inline>
);

export const Item: React.FC<ItemProps> = ({
  item,
  originalItems,
  qtyRight = false,
  moreItemCount,
  hasIcon = true,
  size = ItemSize.sm,
  align = "start",
}) => {
  const itemProductName = getItem(item.dsku, originalItems)?.product?.name;
  const itemName = itemProductName ? itemProductName : "Product name not available";
  const truncateLenth = 10;

  const secondLineMarkup = !!moreItemCount ? (
    <Text as="span" variant="bodyMd" color="subdued">
      <FormattedMessage
        id={`shipment.item.${item.dsku}`}
        defaultMessage="+ {moreItemCount} more "
        values={{ moreItemCount }}
      />
      <FormattedPlural value={moreItemCount} one="item" other="items" />
    </Text>
  ) : (
    <Quantity qty={item.qty} />
  );

  const getItemName = (itemName: string) => {
    if (itemName.length > truncateLenth) {
      return itemName.slice(0, truncateLenth) + "...";
    } else {
      return itemName;
    }
  };

  const getTooltip = (content: string) => {
    return <Tooltip id="itemname-tooltip">{content}</Tooltip>;
  };

  return (
    <div>
      <Inline align="space-between" wrap={false}>
        <Inline gap="3" blockAlign="center" wrap={false} align="end">
          {!!hasIcon && <img src={ItemIcon} />}
          <AlphaStack gap="0" align={align}>
            <Box maxWidth={size}>
              <OverlayTrigger placement="bottom" overlay={getTooltip(itemName)}>
                <p className="font-weight-bold">
                  <span>{getItemName(itemName)}</span>
                </p>
              </OverlayTrigger>
            </Box>
            {!qtyRight && secondLineMarkup}
          </AlphaStack>
        </Inline>
        {qtyRight && <Quantity qty={item.qty} />}
      </Inline>
    </div>
  );
};
