import { MappedTrackingDetail, TrackingDetailResponse } from "~/common/types";

// If map has only one key, return tracking details
export const getTrackingDetailFromMap = (
  trackingDetailMap?: MappedTrackingDetail
): TrackingDetailResponse | undefined => {
  if (!trackingDetailMap) {
    return;
  }
  const trackingCodes = Object.keys(trackingDetailMap);
  if (trackingCodes.length === 1) {
    const trackingCode = trackingCodes[0];
    return trackingDetailMap[trackingCode];
  }
};
