import {
  FulfillmentPackage,
  FulfillmentShipmentWithoutAddress,
  ItemWithProductData,
  MappedTrackingDetail,
  OrderStatus,
} from "common/types";
import ListGroup from "react-bootstrap/ListGroup";

import { FormattedPlural } from "react-intl";
import { ShipmentItems } from "./ShipmentItems";
import { ShipmentPackage } from "./ShipmentPackage";

import { AlphaStack, Box, Text } from "@shopify/polaris";
import { DeliveryMethod } from "common/components/DeliveryMethod";
import { getDeliveryTimeTz } from "common/helpers/OrderEvents/helpers/timezones";
import { getShipmentStatus } from "common/utils/shipment/shipmentStatus";
import { head } from "lodash";
import { useEffect, useState } from "react";
import { Client } from "react-hydration-provider";
import { getShippingMethod } from "~/common/utils/carrier";
import { getDeliveryImageUrl } from "~/common/utils/tracking/getDeliveryImageUrl";

export interface ShipmentProps {
  shipment: FulfillmentShipmentWithoutAddress;
  originalItems: ItemWithProductData[];
  destinationTz: string;
  trackingDetailMap: MappedTrackingDetail;
  isSingleShipment: boolean;
  orderStatus?: OrderStatus;
}

export const Shipment: React.FC<ShipmentProps> = ({
  shipment,
  originalItems,
  destinationTz,
  trackingDetailMap,
  isSingleShipment,
  orderStatus,
}) => {
  const [shipmentStatus, setShipmentStatus] = useState<React.ReactNode>(null);
  const { packages = [], items, from, boxName, estimatedShipTime } = shipment;
  const trackingCode = isSingleShipment ? head(shipment.packages)?.trackingCode : null;
  const trackingDetail = trackingDetailMap[trackingCode!];
  const shippingMethod = getShippingMethod(trackingCode!, trackingDetail, [shipment]);
  const deliveryImageUrl = getDeliveryImageUrl(trackingDetail);

  useEffect(() => {
    setShipmentStatus(getShipmentStatus(shipment, getDeliveryTimeTz(destinationTz)));
  }, [shipment, destinationTz]);

  const qtyMarkup = packages.length ? (
    <>
      {`${packages.length} `}
      <FormattedPlural value={packages.length} one="package" other="packages" />
    </>
  ) : (
    <>
      {`${items.length} `}
      <FormattedPlural value={items.length} one="item" other="items" />
    </>
  );

  const shipmentItems = shipment.items;

  const statusMarkup = (
    <Box>
      <Client>{shipmentStatus}</Client>
      <Text as="span" variant="bodyLg" color="subdued">
        {qtyMarkup}
      </Text>
    </Box>
  );

  const resourceListMarkup = packages.length ? (
    <ListGroup className="rounded-4 w-100">
      {packages.map((pkg: FulfillmentPackage) => (
        <ListGroup.Item className="p-0" key={pkg.id}>
          <ShipmentPackage
            shipmentPackage={pkg}
            originalItems={originalItems}
            from={from}
            boxName={boxName}
            destinationTz={destinationTz}
            trackingDetail={trackingDetailMap[pkg.trackingCode]}
          />
        </ListGroup.Item>
      ))}
    </ListGroup>
  ) : (
    <ShipmentItems
      items={shipmentItems}
      originalItems={originalItems}
      estimatedShipTime={estimatedShipTime}
      destinationTz={destinationTz}
      orderStatus={orderStatus}
    />
  );

  const shipmentMarkup = isSingleShipment ? (
    <AlphaStack gap="4">
      <DeliveryMethod
        trackingCode={trackingCode!}
        shippingMethod={shippingMethod}
        deliveryImageUrl={deliveryImageUrl}
      />
      <ShipmentItems
        items={shipmentItems}
        originalItems={originalItems}
        destinationTz={destinationTz}
        estimatedShipTime={estimatedShipTime}
        orderStatus={orderStatus}
      />
    </AlphaStack>
  ) : (
    <AlphaStack gap="4">
      {statusMarkup}
      {resourceListMarkup}
    </AlphaStack>
  );

  return <Box width="100%">{shipmentMarkup}</Box>;
};
